.site-header {
  .gnav-header-sections {
    &__section-label {
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 0 18px 0 0);
        font-size: 11px;
        letter-spacing: 0.55px;
      }
      @include breakpoint($xlarge-up) {
        @include swap_direction(margin, 0 22px 0 0);
        font-size: 12px;
        letter-spacing: 2.04px;
      }
    }
  }
}
